import update from "immutability-helper";

const initialState = {};

const currentUserReducer = (state = initialState, action) => {
  switch(action.type)
  {
    case "SET_CURRENT_USER":
    {
      return update(state, { $set: action.payload });
    }
    break;
    case "CLEAR_CURRENT_USER":
    {
      return update(state, { $set: {} });
    }
    default:
      return state;
  }
};

export default currentUserReducer
