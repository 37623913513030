import { frFR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles'

export const palette = {
  background: {
    paper: 'rgba(255, 255, 255, 1)',
    default: '#fafafa'
  },
  primary: {
    light: '#0558a3',
    main: '#033059',
    dark: '#0558a3',
    contrastText: '#fff'
  },
  secondary: {
    light: '#0558a3',
    main: '#033059',
    dark: '#0558a3',
    contrastText: '#fff'
  },
  error: {
    light: '#e57373',
    main: '#C32604',
    dark: '#d32f2f',
    contrastText: '#fff'
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)'
  }
}

const theme = createTheme({
  palette,
  components: {
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        fullWidth: true,
        variant: 'outlined'
      }
    },
    MuiSelect: {
      defaultProps: {
        margin: 'dense',
        fullWidth: true,
        variant: 'outlined'
      }
    },
    MuiChip: {
      defaultProps: {
        variant: 'default'
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: 14
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    },
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained'
      }
    },
    typography: {
      defaultProps: {
        fontFamily: 'Noto Sans JP, sans-serif',
        fontSize: 14
      }
    }
  }
},frFR)

export default theme;
