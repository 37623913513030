import Router from 'next/router'
import _ from "lodash";

export const getPageTitle = () => {
  switch (Router.router.pathname) {
    case '/item/[id]':
      switch (Router.router.query.id) {
        case 'new':
          return 'Nouveau support'
        default:
          return 'Édition support'
      }
    case '/item/list':
      return 'Liste des supports'
    case '/item/stats':
      return 'Statistiques'
    case '/item/shared':
      return 'Bibliothèque des supports partagés'
    case '/users':
      return 'Gestion des utilisateurs'
    case '/group/list':
      return 'Gestion des groupes'
    case '/group/[id]':
      switch (Router.router.query.id) {
        case 'new':
          return 'Nouveau groupe'
        default:
          return 'Édition groupe'
      }
    default:
      return 'Mon tableau de bord'
  }
}

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const WORD_REGEX = /^[a-z](['-]?[a-z])*$/i
export const LINE_BREAK_REGEX = /[\r\n\x0C\u0085\u2028\u2029]+/g
