import { combineReducers } from 'redux'

import currentUser from './currentUserReducer'
import users from './userReducer'
import groups from './groupReducer'
import api from './apiReducer'
import noty from './notyReducer'


const appReducers = combineReducers({
  api,
  currentUser,
  users,
  groups,
  noty,
})

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_CURRENT_USER') {
    state = undefined
  }
  return appReducers(state, action)
}
