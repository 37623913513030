import React from "react";
import { apiCall } from "js/api";
import { isAdmin, isContentManager, setToken } from 'js/security';
import { useDispatch } from "react-redux";
import { getCurrentUser } from "store/actions/currentUserActions";
import Router from "next/router";
import { useForm } from "react-hook-form";

import { Box, Button } from "@mui/material";
import { pushNoty } from "store/actions/notyActions";
import ControllerText from "components/commons/input/ControllerText";
import styles from "./login.module.scss";
import jwt_decode from "jwt-decode";
import { EMAIL_REGEX } from "config/settings";

export default function Login() {
  if (Router.route === "/logout") {
    Router.push("/");
  }

  const dispatch = useDispatch();

  const login = async (form) => {
    await apiCall({
      uri: "login",
      method: "POST",
      params: form,
      raw: true,
      init: true
    })
      .then(async (response) => {
        if (response.status === 200) {
          const { roles } = jwt_decode(response.data.token);
          if(isAdmin({ roles }) || isContentManager({ roles }))
          {
            await setToken('token', response.data.token);
            await setToken('refresh_token', response.data.refresh_token);
            await dispatch(getCurrentUser());
            await dispatch(
              pushNoty({
                message: 'Vous êtes connecté',
                severity: 'info'
              })
            );
            Router.push('/')
          }
          else {
            dispatch(
              pushNoty({
                message: 'Vous n\'êtes pas autorisé à vous connecter',
                severity: "error"
              })
            );
          }
        } else {
          await dispatch(
            pushNoty({
              message: "Erreur de token",
              severity: "error"
            })
          );
        }
      })
  };

  const {
    formState: { errors },
    handleSubmit,
    formState,
    control
  } = useForm();

  return (
    <div className={styles.login}>
      <Box boxShadow={8} className={"content"}>
        <div className="heading">
          <div className="title">Connexion à l'admin Skreept</div>
        </div>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(login)}>
          <div className={"email"}>
            <ControllerText
              label="Email"
              name="email"
              control={control}
              variant="standard"
              disabled={formState.isSubmitting}
              error={!!errors.email}
              rules={{
                required: true,
                pattern: EMAIL_REGEX
              }}
            />
          </div>
          <div className={"password"}>
            <ControllerText
              label="Password"
              type="password"
              control={control}
              autoComplete="current-password"
              variant="standard"
              name={"password"}
              disabled={formState.isSubmitting}
              error={!!errors.password}
              rules={{
                required: true
              }}
            />
          </div>
          <div className="button">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              disabled={formState.isSubmitting}
            >
              GO
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
}
