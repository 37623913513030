import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunkMiddleware from 'redux-thunk'
import { rootReducer } from 'store/reducers/rootReducer'

// CREATING INITIAL STORE
const store = (initialState) => {
  const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)))

  // IF REDUCERS WERE CHANGED, RELOAD WITH INITIAL STATE
  if (module.hot) {
    module.hot.accept('store/reducers/rootReducer.js', () => {
      const createNextReducer = require('store/reducers/rootReducer.js').default
      store.replaceReducer(createNextReducer(initialState))
    })
  }

  return store
}

export default store
