import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Grid, Hidden, IconButton, Toolbar, Typography } from '@mui/material'
import { getPageTitle } from 'config/settings'
import { useSelector } from 'react-redux'
import { getRolesList } from 'js/security'
import { AccountCircle } from '@mui/icons-material'
import _ from 'lodash'

function Header({ classes, handleDrawerToggle }) {
  const user = useSelector((state) => state.currentUser)
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" noWrap>
              {getPageTitle()}
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item className={'user-info'} hidden={_.isEmpty(user)}>
              <Typography variant="h7" noWrap>
                <AccountCircle color={'primary'} fontSize={'small'} sx={{ verticalAlign: 'text-bottom' }} />
                {` ${getRolesList(user)} - ${user.email}`}
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header
