import React from 'react'
import { Provider } from 'react-redux'
import withReduxStore from 'store/reduxStoreMaker'
import App from 'components/App';
import { axiosInterceptor } from "js/clientConfiguration";

function AppWrapper(props) {
  const { store } = props

  axiosInterceptor(store);

  return (
    <Provider store={store}>
      <App {...props} />
    </Provider>
  )
}

export default withReduxStore(AppWrapper)
