import React, { useEffect, useState } from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import theme from 'config/theme'
import CssBaseline from '@mui/material/CssBaseline'
import NotyArea from 'components/layout/NotyArea'
import Layout from 'components/layout/Layout'
import { canAccessPage, checkToken, getToken } from 'js/security'
import Login from 'components/login/Login'
import { useDispatch } from 'react-redux'
import { getCurrentUser } from 'store/actions/currentUserActions'
import { CacheProvider } from '@emotion/react'
import createEmotionCache from '../js/createEmotionCache'

const clientSideEmotionCache = createEmotionCache()

function App(props) {
  const { Component, pageProps, err, router, emotionCache = clientSideEmotionCache } = props

  const dispatch = useDispatch()

  const checkUser = async () => {
    const token = getToken()
    return new Promise(async (resolve) => {
      if (token) {
        await dispatch(getCurrentUser())
        resolve()
      } else {
        resolve(true)
      }
    })
  }

  const [loading, setLoading] = useState(true)

  const init = async () => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
    await checkUser()
  }

  useEffect(() => {
    init().then(() => setLoading(false))
    checkToken(45)
  }, [])

  return (
    <CacheProvider value={emotionCache}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotyArea />
          {!loading && canAccessPage(router.pathname) ? (
            <Layout>{canAccessPage(router.pathname) && <Component {...pageProps} err={err} />}</Layout>
          ) : (
            !loading && <Login />
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  )
}

export default App
