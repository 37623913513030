import React from 'react'
import Menu from './Menu'
import { Drawer, useMediaQuery } from '@mui/material'

function LeftBar({ classes, mobileOpen, handleDrawerToggle }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const getDrawer = () => {
    const variant = isMobile ? 'permanent' : 'temporary'
    const modalProps = isMobile
      ? {
          keepMounted: true // Better open performance on mobile.
        }
      : {}

    return (
      <Drawer
        variant={variant}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: `${classes.drawerPaper} MuiPaper-elevation2`
        }}
        ModalProps={modalProps}
      >
        <Menu classes={classes} />
      </Drawer>
    )
  }

  return <nav className={`${classes.drawer} `}>{getDrawer()}</nav>
}

export default LeftBar
