import axios from 'axios'
import NProgress from 'nprogress'
import Router from 'next/router'
import { getToken } from 'js/security'
import qs from 'qs'

export const eject = (reason) => {
  Router.push({
    pathname: '/logout',
    query: { reason }
  })
}

export const apiCall = async (props) => {
  const { uri, method = 'GET', params, raw, hydra = 'member', init, collection = true, headers, responseType } = props

  let token, auth
  if (!init) {
    token = getToken()
    if (!token) {
      await eject('NOT_LOGGED')
      return new Error()
    }
    auth = { Authorization: `Bearer ${token}` }
  }

  const getParams = params && method === 'GET' ? `?${qs.stringify(params, { encodeValuesOnly: true })}` : ''

  return axios({
    url: process.env.NEXT_PUBLIC_API_URI + uri + getParams,
    method,
    data: params,
    responseType: responseType || 'json',
    headers: {
      'content-type': 'application/ld+json',
      ...headers,
      ...auth
    }
  })
    .then((response) => {
      if (raw) {
        return response
      }

      if (method === 'GET' && collection) {
        return response.data[`hydra:${hydra}`]
      }

      if (method === 'POST' || method === 'PUT' || !collection) {
        return response.data
      }
    })
    .catch((error) => {
      NProgress.done()
      console.log(error)
      // if (!init) {
      //   eject(error.response.data.message === 'Expired JWT Token' ? 'EXPIRED_TOKEN' : 'NOT_ALLOWED')
      // }
      return Promise.reject()
    })
}

export const getTotalItems = (entity, params) => {
  return apiCall({
    uri: entity,
    params,
    hydra: 'totalItems'
  })
    .then((items) => Promise.resolve(items))
    .catch((error) => Promise.reject(error))
}

export const getApiErrorMessage = (error) => {
  if (!error.response) {
    return `Erreur de connexion à l'API`
  }

  const errorMessage = [`Erreur code ${error.response.status}`]

  switch (error.response.status) {
    case 401: {
      errorMessage.push(error.response.data.message)
      if (error.response.data.message === 'Expired JWT Token') {
        eject('EXPIRED_TOKEN')
      }

      break
    }
    case 404: {
      errorMessage.push(`Ressource non trouvée`)
      break
    }
    case 400:
    case 403:
    case 422: {
      console.log(error.response.data.detail)
      errorMessage.push(
        error.response.data.detail ? error.response.data.detail : error.response.data['hydra:description']
      )
      break
    }
    case 500: {
      errorMessage.push(error.response.data)
      break
    }
    default:
      errorMessage.push(error.response.statusText)
      break
  }
  return errorMessage.join(' ')
}
