import update from 'immutability-helper'

const initialState = null

const notyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PUSH_NOTY': {
      return update(state, { $set: action.payload })
    }
    default:
      return state
  }
}
export default notyReducer
