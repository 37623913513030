import update from 'immutability-helper'

const initialState = {
  users: { isFetched: false },
  groups: { isFetched: false }
}

const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FETCHED': {
      return update(state, { [action.payload.entity]: { $set: { isFetched: true } } })
    }
    default:
      return state
  }
}

export default apiReducer
