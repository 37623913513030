import axios from 'axios'
import NProgress from 'nprogress'
import { pushNoty } from 'store/actions/notyActions'
import { getApiErrorMessage } from 'js/api'

export const axiosInterceptor = (store) => {
  axios.interceptors.request.use(
    (config) => {
      NProgress.start()
      return config
    },
    (error) => Promise.reject(error)
  )

  axios.interceptors.response.use(
    async (response) => {
      NProgress.done()
      return response
    },
    (error) => Promise.reject(error)
  )

  axios.interceptors.response.use(
    async (response) => {
      NProgress.done()
      return response
    },
    (error) => Promise.reject(error)
  )

  axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next)
    },
    (error) => {
      const errorMessage = getApiErrorMessage(error)
      store.dispatch(
        pushNoty({
          message: errorMessage,
          severity: 'error'
        })
      )
      return Promise.reject(error)
    }
  )
}
