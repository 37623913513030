import { apiCall } from 'js/api'
import { isAdmin, isContentManager } from 'js/security';

export const getCurrentUser = () => {
  return (dispatch) => {
    return apiCall({
      uri: 'me',
      collection: false
    })
      .then((user) => {
        if (user) {
          if (!isAdmin(user) && !isContentManager(user)) {
            return new Error()
          }
          dispatch({
            type: 'SET_CURRENT_USER',
            payload: user
          })
        }
      })
      .catch((error) => console.log(error))
  }
}

export const logoutCurrentUser = () => {
  return (dispatch) => {
    dispatch({ type: 'LOGOUT_CURRENT_USER' })
  }
}
