import React from 'react';

import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const ControllerText = ({
  rules,
  label,
  name,
  rows = 6,
  defaultValue,
  control,
  onChange,
  helperText,
  onBlur,
  readOnly = false,
  ...textFieldProps
}) => {

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={rules}
      render={({
        field,
        fieldState: { invalid,error }
      }) => {
        return (
          <TextField
            {...field}
            label={label && `${label} ${rules?.required ? '*' : ''}`}
            error={invalid}
            helperText={invalid && (helperText ? helperText : error.message)}
            rows={rows}
            onChange={(e) => {
              const value = e.target.value;
              field.onChange(value);
              onChange && onChange(e);
            }}
            onBlur={(e) => {
              onBlur && onBlur(e);
            }}
            {...textFieldProps}
            InputProps={{
              ...textFieldProps.InputProps,
              readOnly
            }}
          />
        );
      }}
    />
  );
};

export default ControllerText;
