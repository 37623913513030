import React, { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useSelector } from 'react-redux'

function NotyArea() {
  const [notyShow, setNotyShow] = useState(false)

  const noty = useSelector((state) => state.noty)
  useEffect(() => {
    setNotyShow(!!noty)
  }, [noty])
  return (
    <Snackbar
      open={notyShow}
      autoHideDuration={6000}
      onClose={() => setNotyShow(false)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      {noty && (
        <Alert variant="filled" onClose={() => setNotyShow(false)} severity={noty.severity}>
          {noty.message}
        </Alert>
      )}
    </Snackbar>
  )
}

export default NotyArea
