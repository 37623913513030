import update from "immutability-helper";
import _ from 'lodash'
const initialState = [];

const userReducer = (state = initialState, action) => {
  switch(action.type)
  {
    case "GET_USERS":
    {
      return update(state, { $set: action.payload });
    }
    case "ADD_USER":
    {
      return update(state, {$push:[action.payload]})
    }
    case "UPDATE_USER":
    {
      const index = _.findIndex(state, {'@id' : action.payload['@id']});
      return update(state, {[index] : { $set: action.payload }})
    }
    case "DELETE_USER":
    {
      const index = _.findIndex(state, {'@id' : action.payload['@id']});
      return update(state, {$splice:[[index,1]]})
    }
    default:
      return state;
  }
};

export default userReducer
