import jwt_decode from 'jwt-decode'
import SetInterval from 'set-interval'
import { apiCall, eject } from 'js/api'
import dayjs from 'dayjs'
import _ from 'lodash'

export const setToken = (type, value) => {
  localStorage.setItem(type, value)
}
export const getToken = (type = 'token') => (typeof window !== 'undefined' && localStorage.getItem(type)) || false

export const getCurrentUserRoles = () => {
  const token = getToken()
  if (!token) {
    return false
  }
  if (token) {
    const { roles } = jwt_decode(token)
    return roles
  }
  return []
}

export const hasRoles = (roles, givenUser = null) => {
  let userRoles = null
  if (givenUser) {
    if (!givenUser.roles) {
      return false
    }
    userRoles = givenUser.roles
  } else {
    userRoles = getCurrentUserRoles()
  }
  return _.intersection(roles, userRoles).length > 0
}

export const isAdmin = (givenUser = null) => {
  return hasRoles(['ROLE_ADMIN'], givenUser)
}
export const isContentManager = (givenUser = null) => {
  return hasRoles(['ROLE_CONTENT_MANAGER'], givenUser)
}
export const isUser = (givenUser = null) => {
  return hasRoles(['ROLE_USER'], givenUser)
}

export const getRolesList = (user) => {
  if (!user || !user.roles) return false
  const roles = user.roles
    .map((role) => {
      const label = _.find(ROLES, { value: role })
      if (label) {
        return label.label
      }
      return ''
    })
    .join(', ')
  return roles
}
export const canAccessPage = (page, user = null) => {
  switch (page) {
    case '/users':
      return isAdmin(user)
    case '/logout':
      return true
    default:
      return isAdmin(user) || isContentManager(user)
  }
}

export const refreshToken = async () => {
  const refreshToken = getToken('refresh_token')
  apiCall({
    uri: 'token/refresh',
    method: 'POST',
    params: { refresh_token: refreshToken },
    raw: true,
    init: true
  }).then(async (response) => {
    if (response.status === 200) {
      await setToken('token', response.data.token)
    }
    if (response.status === 401) {
      await eject('EXPIRED_TOKEN')
    }
  })
}

//period in minutes
export const checkToken = (period) => {
  SetInterval.start(
    async () => {
      const token = getToken()
      if (token) {
        try {
          const { exp } = jwt_decode(token)
          const tokenTime = dayjs(exp * 1000)
          const now = dayjs()
          const expireIn = tokenTime.diff(now, 'hours')
          if (expireIn < 2) {
            await refreshToken()
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    period * 1000 * 60,
    'tokenChecker'
  )
}

export const clearTokenChecker = () => {
  SetInterval.clear('tokenChecker')
}

export const ROLES = [
  {
    label: 'Administrator',
    value: 'ROLE_ADMIN'
  },
  {
    label: 'Content manager',
    value: 'ROLE_CONTENT_MANAGER'
  },
  {
    label: 'User',
    value: 'ROLE_USER'
  }
]
