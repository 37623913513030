import React from 'react'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import DashboardIcon from '@mui/icons-material/Dashboard'
import GroupIcon from '@mui/icons-material/Group'
import EqualizerIcon from '@mui/icons-material/Equalizer'

import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import { Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import Link from 'components/commons/Link'
import Image from 'components/commons/Image'
import { RenderIfAuthorized } from 'components/commons/RenderIfAuthorized'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

export default function Menu({ classes }) {
  return (
    <div>
      <div className={`${classes.toolbar} logo`}>
        <Link href="/">
          <Image src="/images/logo_white.png" alt={'logo'} width={204} height={47} />
        </Link>
      </div>
      <Divider />
      <List className="menu">
        <ListItem component={Link} href="/">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText>Tableau de bord</ListItemText>
        </ListItem>
        <Divider />
        <ListSubheader>Supports</ListSubheader>
        <ListItem component={Link} href="/item/list">
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText>Mes supports</ListItemText>
        </ListItem>
        <ListItem component={Link} href="/item/stats">
          <ListItemIcon>
            <EqualizerIcon />
          </ListItemIcon>
          <ListItemText>Statistiques</ListItemText>
        </ListItem>
        <Divider />
        <ListItem component={Link} href="/item/shared">
          <ListItemIcon>
            <LocalLibraryIcon />
          </ListItemIcon>
          <ListItemText>Bibliothèque support</ListItemText>
        </ListItem>
        <Divider />
        <ListSubheader>Gestion</ListSubheader>
        <RenderIfAuthorized roles={['ROLE_ADMIN']}>
          <ListItem component={Link} href="/users">
            <ListItemIcon>
              <RecordVoiceOverIcon />
            </ListItemIcon>
            <ListItemText>Utilisateurs</ListItemText>
          </ListItem>
          <Divider />
        </RenderIfAuthorized>
        <ListItem component={Link} href="/group/list">
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText>Groupes</ListItemText>
        </ListItem>
        <Divider />
        <ListSubheader>Compte</ListSubheader>
        <ListItem component={Link} href={process.env.NEXT_PUBLIC_FRONT_URI} target={'_blank'}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Voir le site</ListItemText>
        </ListItem>
        <ListItem component={Link} href="/logout">
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText>Déconnexion</ListItemText>
        </ListItem>
      </List>
    </div>
  )
}
