import update from 'immutability-helper'
import _ from 'lodash'

const initialState = []

const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_GROUPS": {
      return update(state, { $set: action.payload });
    }
    case "ADD_GROUP": {
      return update(state, { $push: [action.payload] });
    }
    case "UPDATE_GROUP": {
      const index = _.findIndex(state, { "@id": action.payload["@id"] });
      return update(state, { [index]: { $set: action.payload } });
    }
    case "DELETE_GROUP": {
      const index = _.findIndex(state, { "@id": action.payload["@id"] });
      return update(state, { $splice: [[index, 1]] });
    }
    case "GROUP_INVITE_MEMBER": {
      const groupIndex = _.findIndex(state, { "@id": action.payload.group });
      return update(state, { [groupIndex]: { members: { $push: [action.payload.member] } } });
    }
    default:
      return state;
  }
};

export default groupReducer;
